/* You can add global styles to this file, and also import other style files */


  
  @keyframes loadereffect {
    from {
      height: 0;
    }
    50% {
      height: 40px;
    }
    to {
      height: 0;
    }
  }
  @-webkit-keyframes loadereffect {
    from {
      height: 0;
    }
    50% {
      height: 40px;
    }
    to {
      height: 0;
    }
  }
  


.full-width-auto-complete .ui-autocomplete .ui-autocomplete-input {
    width: 100%;
    height: calc(1.5em + 1.33rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.pharmecy-search-model .ui-dialog .ui-dialog-content {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    position: relative;
    overflow: visible;
    min-height: 250px;
}

.ui-dialog-mask.ui-widget-overlay {
    pointer-events: auto;
    z-index: 1111 !important;
}

.dedication-auto-complete .ui-autocomplete .ui-autocomplete-input {
    height: 35px;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}


body .ui-inputtext {
    border-color: #dcdcdc;
    color: #858585;
    font-size: 13px;
    height: 43px;
    padding: 6px 15px;
    width: 100%;

}
body .form-section-inner .ui-inputtext{
    height: 34px;
}

.error-message {
    border-bottom: 3px solid red !important;
}

.input-mask {
    border-bottom: none !important;
}

.error-message-text {
    color: red;
    font-size: 12px;

}

.errortextbox {

    background-color: lemonchiffon;
}

.auto-complete-search-input .ui-autocomplete .ui-autocomplete-input {
    border-color: #dcdcdc;
    color: #858585;
    font-size: 13px;
    min-height: 42px;
    width: 100%;
    padding: 6px 15px;
}

.auto-complete-search-input.invalid-Input .ui-autocomplete .ui-autocomplete-input {
    border-bottom: 3px solid red !important;
}

.department-slider .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item>.ui-button {
    display: none !important;
}

.department-slider .ui-carousel .ui-carousel-content .ui-carousel-prev {
    display: none !important;
}

body .card-design2 .ui-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: #dcdcdc;
}

body .card-design2 .ui-inputtext:enabled:focus:not(.ui-state-error) {
    border-color: #dcdcdc;
}

.error-message .ui-inputtext {
    border-bottom: 3px solid red !important;
}

.zip-message .ui-inputtext {
    border-bottom: 3px solid red !important;
}

.year-on-booking .ui-inputtext {
    min-height: 37px;
}

.input-mask input {
    height: 40px;
}

.error-message.auto-complete-search-input .ui-autocomplete-input {
    border-color: #dcdcdc !important;
    border-bottom: 3px solid red !important;

}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
    color: #333333;
    background-color: transparent;
}

canvas {
    touch-action: none;
    width: 100% !important;
    border: 1px solid #ccc !important;
    margin-top: 20px;
}



.full-screen-modal .ui-dialog {
    width: 60% !important;
    min-width: 200px;
    max-height: 90%;
    top: 30.0156px;
}

.inclinick-form .ui-table table {
    border-collapse: collapse;
    width: 100%;
    table-layout: auto;
}

.inclinick-form .btn-primary {
    -webkit-appearance: none;
    white-space: nowrap;
}

.inclinick-form .ui-table tr th {
    white-space: nowrap;
}

.custom-cal span {
    display: block;
}

.ui-dropdown {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    transition: border-color 0.2s, box-shadow 0.2s;
    width: 100%;
}

.timezone .ui-inputtext {
    padding: 6px 15px !important;
    line-height: 25px;

}

body .assigin-card .ui-chkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

body .ui-chkbox .ui-chkbox-box {
    border-radius: 6px;

}

body .assigin-card .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    overflow: hidden;
    position: relative;
    font-size: 11px;
    line-height: 17px;
}

.owl-prev {
    position: absolute;
    top: 36%;
    left: -34px;
    background: #002677;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    font-size: 20px;

}

.owl-next {
    position: absolute;
    top: 36%;
    right: -34px;
    background: #002677;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    font-size: 20px;

}

body .password-field input {
    border: none;
    padding-left: 10px !important;
}

body .ui-radiobutton-label {
    font-size: 17px;
    color: #000;
}

body .patient-info-form .ui-inputtext {
    padding: 6px 7px !important;
    border: none;
    padding-left: 0 !important;
    height: 32px;

}

// body .patient-info-form .ng-dirty.ng-invalid {
//     border: none !important;

// }

body .patient-info-form p-inputmask.ng-dirty.ng-invalid>.ui-inputtext {
    border: none;
}

.inteck-insurance {
    width: 100%;
}
.inteck-insurance .ui-dropdown-label-container {
    overflow: hidden;
    padding-right: 33px;

}
.inteck-insurance .ui-dropdown-label {
    line-height: 12px;
    font-size: 12px;
    padding-top: 10px !important;
    padding-left: 10px !important;
    color: #858585;
    white-space: nowrap;
    
}

.inteck-insurance .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
    top: 40%;
    left: 41%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    position: absolute;
    background: #006BD9;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    font-size: 10px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    opacity: 0.8;

}

.inteck-insurance .ui-dropdown {
    background: #E8F3FF;
    height: 32px;
    border: 1px solid #0058B3;
    background-color: transparent;
    border: 1px solid #8e9aa6;
    display: block;
}

body .inteck-insurance .ui-dropdown .ui-dropdown-trigger {
    background: transparent;
}

body .password-field p-inputmask.ng-dirty.ng-invalid>.ui-inputtext {
    border: none;
}

body .patient-pharmacy .ui-autocomplete .ui-autocomplete-input {
    background: #fff;
    padding: 6px 15px !important;
}

body .patient-pharmacy .ui-autocomplete .ui-autocomplete-input:hover {
    border-color: #fff !important;
    padding: 6px 15px !important;
}

.custom_serch .loader {
    top: 38px !important;
    right: 9px !important;
    width: 20px !important;
    height: 20px !important;
    left: auto !important;
    z-index: 999;
}

.urjentcare-sidebar {
    width: 20%;
    position: fixed;
}

.urjentcare-wrapper {
    min-width: 0;
    width: 100%;
    position: relative;
    margin-left: 20%;
    margin-top: 14px;
}

body .contact-body .ui-widget {
    display: block;

}

.serch-autocpmplate {
    .ui-autocomplete {
        width: 100%;
        display: block;
        position: static;
    }

}

body .schedule-checking-screen .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    box-shadow: none;
    border: 1px solid #ccc !important;
}

body .schedule-checking-screen .input-mask .ui-inputtext {
    height: 42px;
    padding: 6px 10px !important;
}

body .schedule-checking-screen .ui-dropdown-trigger-icon {
    font-size: 12px;
}
.steps{
    .ui-radiobutton-box{
        width: 18px !important;
        height: 18px !important;
    }
}
@media(max-width:788px) {
    .UC-urgent-care-sidebar {
        position: fixed;
        left: 0;
        z-index: 9999999999;
        background: #fff;
        width: 100%;
        max-width: 65%;
        top: 0;
        -webkit-transition: all .5s linear;
        -moz-transition: all .5s linear;
        transform: translate(-100%);
        transition-duration: .4s;
        transition-property: transform;
        height: 100Vh;
        width: 130px !important;
    }

    .urgent-care-sidebar-open .UC-urgent-care-sidebar {
        transform: translate(0);
        max-width: 75%;
        padding: 13px 8px;
        overflow-y: auto;
    }

    body .delete-insurance .ui-dialog .ui-dialog-footer button {
        min-width: 86px;
        width: auto;
    }

    body .widget-categories .ui-radiobutton .ui-radiobutton-box {
        width: 16px;
        height: 16px;
    }

    body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
        width: 8px;
        height: 8px;
        margin-left: -4px;
        margin-top: -4px;
    }

    .owl-nav {
        display: none;
    }

    .full-screen-modal .ui-dialog {
        width: 100% !important;
        min-width: 200px;
        max-height: 90%;
        top: 30.0156px;
    }
}

.video-upper-sec.local-video .w-100 {
    position: relative;
}

.video-upper-sec.local-video video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.video-upper-sec.local-video.small-thumb-video video {
    height: 100px;
    width: 100%;
    position: static;
}
body .add-pharmacy .ui-autocomplete .ui-autocomplete-input{
    background-color: #fff;
    border-radius: 4px;
    height: 50px !important;
    color: #000;
    padding-left: 10px !important;
    border-radius: 10px;
    border-color: #9999bb;
}
body .add-pharmacy .ui-autocomplete .ui-autocomplete-input::placeholder{
    color: #1C1C1C !important;
    font-size: 11px !important;
    font-weight: 400;
    font-family: "Lato", sans-serif;
}

body .site-map-container  .ui-paginator .ui-paginator-pages .ui-paginator-page{
    height: auto;
    line-height: 24px;
    padding: 5px;
    margin: 0 10px;
    vertical-align: top
}

.site-map-container .ui-paginator-first , .site-map-container  .ui-paginator-prev , .site-map-container  .ui-paginator-next , .site-map-container  .ui-paginator-last {
margin-top: 10px !important;
}
.appartment-types{
    margin-top: 10px;
}
body .site-map-container .ui-paginator .ui-paginator-pages{
    vertical-align: top;
    display: inline-block;
    padding: 0;
    margin-top: 10px;
}
.owl-carousel {
    display: none;
    width: 100%;
    z-index: inherit;
    font-size: 11px !important;
    font-weight: 400;
}
body  .reasonfor-vist-screen .ui-listbox .ui-listbox-list{
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
}
@media only screen and (min-width: 600px) {
    body .credit-card-popup .ui-dialog-content{
        overflow: hidden;
    }
   
}

@media only screen and (max-width: 600px) {
    body .reasonfor-vist-screen .ui-listbox .ui-listbox-list {
        height: auto;
        max-height: 400px;
        overflow: auto;
        overflow-x: hidden;
    }
    .crop-div.hightfixed {
        margin-bottom: 5px;
        // height: 200px;
    }
       
    .video-big video {
        object-fit: cover;
        overflow: hidden;
    }
// .cropper{
//     height: 190px !important;
// }
    .video-upper-sec.local-video video {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

html,
body {
    -webkit-overflow-scroll: none;
}

.allinOneStaps .ui-steps .ui-steps-item .ui-menuitem-link:focus {
    box-shadow: none;
}

@media only screen and (max-width: 480px) {
    .input-mask input {
        font-size: 11px !important;
        padding: 6px 8px;
    }

    input::placeholder {
        font-size: 10px !important;
    }
}

.auto-complete-search-input .ui-autocomplete-dd .ui-autocomplete-dropdown.ui-corner-all {
    position: absolute;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    border: 1px solid #ccc;
    color: #998f8f;
    font-size: 11px;
}

body {
    background-color: #ffffff;
}

.contact-form .card {
    box-shadow: none;
}

.allbooking-flow {
    margin: 0;
}

p.mt15.dep-title {
    padding: 10px 20px 0;
}

.ap-slot-left p.mt15.dep-title {
    position: relative;
    padding: 0px 22px 5px;
    overflow: hidden;
    position: relative;
    padding: 19px 22px 15px;
    margin-top: 11px;
    padding-bottom: 0;
}

.booking-form {
    position: relative;
    padding: 1px 0px 20px;
}

.time_button-Wrapper .loadMore {
    width: 32%;
}

.time_button-Wrapper button {
    width: 31%;
    margin-right: 6px;
    margin-bottom: 10px;
}

.time_button-Wrapper button li a.confirm-time {
    padding: 13px 5px;
}

.ui-carousel-container img {
    width: fit-content;

}

.appartment-types label {
    position: absolute;
    background-color: #145e9c;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 13px;
}

.form-group.select-vist-form {
    margin-top: 14px;
    display: flex;
   // box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    border: 1px solid #ccc;
    padding: 15px;
}

h4.book-date-head {
    position: relative;
    font-size: 13px;
    color: #000;
    top: -22px;
    left: 20px;
}

body .book-date-head .ui-inputtext {
    background: transparent;
    border: none;
    background-image: url('assets/img/cal-icon.png');

    background-size: cover;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: 44px;
    background-color: transparent;
    width: 44px;
    color: transparent;
    cursor: pointer;
}

body .custom-cal-type2 .ui-inputtext {
    background: transparent;
    border: none;
    background-image: url('assets/img/cal-icon.png');

    background-size: cover;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: 34px;
    background-color: transparent;
    width: 44px;
    color: transparent;
    cursor: pointer;
}

button.btn-next {
    margin-left: 5px;
    background-color: #ffffff;
    border: 2px solid #145e9c;
    color: #145e9c;
}

button.btn-previous {
    margin-left: 5px;
    background-color: #ffffff;
    border: 2px solid #145e9c;
    color: #145e9c;
    margin-right: 0px;

}

.department_detail svg {
    margin-right: 5px;
    color: #145e9c;
}

.no-search-box {
    border: 3px dotted #145e9c !important;
    background-color: aliceblue;
}

.ui-carousel-items-container {
    justify-content: center;
}

.ui-carousel-container .p-col-12 {
    margin: 5px;
    background: none;
    box-shadow: none;
    text-align: center;
}

ul.department_detail {
    padding-bottom: 10px;
    list-style: none;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    margin: 0px 0 12px;
}

p.mt15.dep-title {
    position: relative;
    padding: 0px 22px 5px;
    border-radius: 12px;
    overflow: hidden;
}

.appointment-crf .strong-title {
    font-size: 17px;
    letter-spacing: 0;
}

.appointment-crf p.mt15.dep-title {
    position: relative;
    padding: 0;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 0;
}

.ap-slot-left {
    margin-top: -10px;
    margin-right: 21px;
}

.ap-slot-right {
    position: relative;
    padding: 15px 22px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%);
    background: #fff;
    margin-bottom: 60px;;
}

.appointment-crf .booking-form {
    position: relative;
    padding: 1px 0px 20px;
    position: sticky;
    top: 0;
}

.appointment-crf .ap-slot-right {
    position: relative;
    margin-top: 0%;
    padding: 0;
    border-radius: 12px;
    box-shadow: none;
}


// #testimonials{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     width:100%;
// }
#testimonials .testimonial-heading {
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#testimonials .testimonial-heading span {
    font-size: 1.3rem;
    color: #252525;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

// #testimonials .testimonial-box-container{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: wrap;
//     width:100%;
// }
#testimonials .testimonial-box {
    /* width: 500px; */
    /* box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1); */
    background-color: #ffffff;
    padding: 16px;
    margin-bottom: 21px;
    cursor: pointer;
    border-radius: 14px;
}

#testimonials .profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

#testimonials .profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#testimonials .profile {
    display: flex;
    align-items: center;
}

#testimonials .name-user {
    display: flex;
    flex-direction: column;
}

#testimonials .name-user strong {
    color: #3d3d3d;
    font-size: 14px;
    letter-spacing: 0;
}

#testimonials .name-user span {
    color: #979797;
    font-size: 0.8rem;
}

#testimonials .reviews {
    color: #f9d71c;
}

#testimonials .box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#testimonials .client-comment p {
    font-size: 14px;
    color: #4b4b4b;
    margin-bottom: 0;
}

body .ui-messages.ui-messages-error .ui-messages-icon {
    color: #fff;
}

body .ui-messages.ui-messages-error .ui-messages-icon:before {
    content: "\e922";
}

body .ui-messages.ui-messages-error {
    background: #ffe7e6;
    border: solid #ff5757;
    border-width: 0 0 0 6px;
    color: #ff5757;
}
.review-section{
    .owl-prev{
        left: 0;
        background: transparent;
    }
    .owl-next{
        right: 0;
        background: transparent;
    }
    .owl-dots{
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        .owl-dot{
            display: inline-block;
            margin-left: 2px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            border: 1px solid #1F2531;
            padding: 7px;
            line-height: 8px;

            span{
                width: 8px;
                height: 8px;
                background: #fff;
                display: inline-block;
                border-radius: 100%;
                position: relative;
                bottom: 4px;
                right: 4px;
            }
        }
       
        .owl-dot.active{
            display: inline-block;
            margin-left: 2px;
            border: 1px solid #07BDEF;

            span{
                background: #07BDEF;
            }
        }
    }
}
@media(max-width:1080px) {
    #testimonials .testimonial-box {
        width: 45%;
        padding: 10px;
    }
    .hide-on-phone{
        display: none;
    }
}

@media(max-width:790px) {
    .appointment-crf .card .col-lg-7 h3 {
        font-size: 17px !important;
    }

    #testimonials .testimonial-box {
        width: 45%;
        padding: 5%;
        border-radius: 10px;
    }

    .appointment-crf .ap-slot-left {
        border-radius: 9px !important;
        padding: 5% !important;
    }

    .appointment-crf .dep-title .department_detail {
        font-size: 11px;
        margin-bottom: 0;
    }

    #testimonials .testimonial-box {
        width: 100%;
    }

    #testimonials .testimonial-heading h1 {
        font-size: 1.4rem;
    }
}

@media(max-width:340px) {
    #testimonials .box-top {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    #testimonials .reviews {
        margin-top: 10px;
    }
}



.book-ap-left ul.department_detail {
    border-bottom: none;
    padding-bottom: 0;
    margin: 0px 0 3px;
}

.ab-icon {
    position: absolute;
    right: -13px;
    bottom: -10px;
}

.ab-icon svg {
    color: #145e9c;
    opacity: 0.5;
    z-index: -1;
    width: 75px;
    height: 76px;
}

li.dep-det-op {
    color: #878791;
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 16px;
}

h4.card-title.pat-info {
    margin-top: 0;
}

.editbtn button {
    border: none;
    background: none;
    margin-bottom: 11px;
    margin-top: 10px;
    border: 1px dotted #ccc;
}

.editbtn button.active {
    box-shadow: 3px 4px 15px #00000026;
}

.editbtn button i {
    color: #145e9c !important;
}

select.form-control {
    background-size: 20px;
    -webkit-appearance: none;
    position: relative;
    background-image: url('assets/img/drop.png');
    background-repeat: no-repeat;
    background-position: 98%;
    background-color: transparent;
    cursor: pointer;
}

body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
    cursor: pointer;
}

.form-control:focus {
    background-color: transparent;
    border-color: #dcdcdc;
}

.form-control:disabled:focus,
.form-control[readonly]:focus {
    background-color: #e9ecef;
    opacity: 1;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #dcdcdc;
    box-shadow: none;
}

.form-control.icons {
    padding: 6px 10px 6px 31px;

}

.form-control.icons {
    padding: 6px 10px 6px 31px;

}

body .ui-inputtext {
    padding: 6px 10px 6px 31px !important;
}

body .form-section .ui-inputtext {
    padding: 6px 10px !important;
}


.custom-cal input {
    padding: 6px 15px !important;
}

body .input-mask.no-icon .ui-inputtext {
    padding: 6px 10px !important;
}

body .auto-complete-search-input.no-icon .ui-inputtext {
    padding: 6px 10px !important;
}

span.input-group-addon.book-form {
    position: absolute;
    top: 38px;
    left: 25px;
    color: #d1d1d1;
}

.content {
    max-width: 100%;
}

p.description {
    color: rgb(0, 35, 75);
    font-weight: 200;
    font-size: 15px;
}

.time_button-Wrapper button li a.confirm-btn {
    font-size: 13px;
    padding: 13px 8px !important;
}

.ins-info .form-control {
    padding: 6px 10px 6px 14px !important;
}

label.p-10.new-head {
    color: #1f2e89;
    padding-bottom: 11px;
    padding-top: 1px;
}

.custom-popup .ui-dialog-titlebar {
    background-color: #145e9c !important;
}

.book-ap-left p.mt15.dep-title {
    position: relative;
    padding: 19px 22px 15px;
    background-color: #f8f7ff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%);
    margin-top: 0;
}

.book-ap-right {
    border: 1px solid #ebebeb;
    padding: 20px;
    margin-top: 3%;
    border-radius: 5px;
    margin-bottom: 60px;
    background: #fff;

}

h4.card-title.pat-info {
    margin-top: 0;
    position: absolute;
    background-color: #145e9c;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    left: 50%;
    top: -65px;
    transform: translate(-50%, -6%);
    font-size: 15px;
}

.booking-form .alert-warning {
    margin-top: -2px;
    margin-bottom: 18px;
}

.btn-primary {
    letter-spacing: 0.05em;
    font-size: 13px;
}

.appointment-thanks-2 {
    border: none;
    padding: 0;
    text-align: center;
}

h4.heading-appointment {
    background-color: aliceblue;
    padding: 10px;
    font-size: 17px;
    color: #145e9c;
    margin-top: -3px;
    margin-bottom: 20px;
}

.appointment-thanks-2 .ap-book span {
    margin-right: 10px;
    color: #145e9c;
}

.appointment-thanks-2 {
    font-size: 14px !important;
    line-height: 20px;
}

#msform {
    margin-top: 3%;
}

img.img-responsive.order-confirm-img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 60px;
}

.pat-name {
    font-size: 16px;
}

.card-design2 .input-group-addon.book-form {
    top: 8px;
}

.appointment-thanks-2 .book-con {
    border-bottom: 1px solid #ebeaea;
    padding-bottom: 13px;
    margin-bottom: 14px;
}

.appointment-thanks-2 .book-con span {
    margin-right: 10px;
    color: #145e9c;
}

.col-red {
    color: #c41a20;
}

.mr-b {
    margin-bottom: 0px;
}

section.appointment-thanks-2 p {
    font-size: 17px;
}

.button-round.btnred {
    background-color: #c41a20;
    color: #fff;
    border-color: #c41a20;
}

.up-ins-img {
    margin-bottom: 10px;
    width: 100%;
}


.mt15.dep-title span .icon {
    display: contents;
}

.mt15.dep-title .icon svg {

    margin-right: 5px;
    color: #145e9c;
}

.pic-con {
    text-align: center;
    font-weight: 600;
    padding-bottom: 18px;
}

.upload-file-btn {
    margin-top: 20px;
}

.ap-slot-right h4.up-head {
    position: absolute;
    background-color: #145e9c;
    color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    left: 30%;
    font-size: 13px;
    top: -53px;
}

.appointment-crf .header {
    top: 0;
    z-index: 9999;
    width: 100%;
    border-bottom: 1px solid #0000001f;
}

.appointment-crf .ap-slot-right h4.up-head {
    position: static;
    background-color: #ffffff;
    color: #3f6bb3;
    padding-bottom: 11px;
    border-radius: 4px;
    border: 1px solid #e5e5e500;
    left: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    top: -53px;
    letter-spacing: initial;
}

.appointment-crf .second-box {
    margin-top: 1rem;

    span.mb-4 {
        float: right;
        margin-bottom: 0 !important;
    }

}

.ups-head {
    font-size: 32px;
    color: #26487f;
    font-family: serif;
    font-style: italic;
    /* padding: 0; */
    border-bottom: 1px solid #fff;
    position: static;
    background-color: #ffffff;
    padding-bottom: 11px;
    border-radius: 4px;
    left: 0;
    margin: 0;
    font-weight: 600;
    top: -53px;
    letter-spacing: normal;
    letter-spacing: initial;
}

.check-qa {
    display: flex;



    .form-group {
        font-size: 15px;
        letter-spacing: normal;
        margin: 7px;
        font-weight: 100;

        label {
            display: inline-block;
            max-width: 100%;
            margin: 6px;
            font-weight: 100;
            font-size: 17px;
            color: #7d7d7d;
        }

    }

}

.thanks-popup {
    img {
        margin-bottom: 3%;

    }

    li {
        color: #ffc906 !important;
    }
}

.para-pp {
    font-size: 18px;
    letter-spacing: normal;
    font-weight: 100;
    color: #a1a1a1;
    width: 74%;
    line-height: normal;
    margin-bottom: 5%;
}

.appointment-crf .appartment-types {
    font-size: 13px;
    padding: 0 3%;
    margin-top: 0 !important;
}

.appointment-crf .select-vist-form span {
    font-size: 15px;
    text-align: left;
    margin-top: 0px;
}

.appointment-crf label.extra-style-head {
    position: static;
    top: -23px;
    left: 37%;
    transform: inherit;
    border: none;
    background-color: #fff;
    color: #03a9f4;
    font-size: 17px;
    letter-spacing: 0;
    border-radius: 6px;
    padding: 0;
    margin-bottom: -5px;
}

.appointment-crf .second-box2 {
    margin-top: 0rem;

    span.mb-4 {
        float: right;
        margin-bottom: 0 !important;
    }
}

.appointment-crf .can-ap-sec label span {
    color: #03A9F4;
}

.appointment-crf .row.can-ap-sec {
    margin: 10px 0 10px;
    background-color: white;
    padding: 0;
    /* margin-bottom: 30px; */
}

.appointment-crf .ui-carousel-container img {
    max-width: 100%;
    min-width: 100%;
    background: white;
    height: 128px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0px;
    border-radius: 24px;
    padding: 11px;
    border: 5px solid #adc4cf14;
    box-shadow: none;
}

.appointment-crf .ap-slot-left {
    border-radius: 14px;
    margin-top: -10px;
    margin-right: 0;
    background: white;
    padding: 4%;
}

.appointment-crf h4.book-date-head {
    position: relative;
    font-size: 17px;
    color: #1969ad;
    top: 3px;
    left: -8px;
    letter-spacing: 0;
    font-weight: 600;

    span {
        margin-right: 8px;

        svg {
            color: #ff2626;
        }
    }
}

.appointment-crf .card .col-lg-7 h4.up-head {
    position: static;
    background-color: none;
    padding: 0px 0;
    color: #295aabe3;
    padding-bottom: 11px;
    /* border-radius: 4px; */
    /* border-right: 0px; */
    border-bottom: 1px solid #3f51b52e;
    left: 0;
    margin-bottom: 4%;
    font-size: 21px;
    font-weight: 600;
    top: -53px;
    letter-spacing: normal;
    letter-spacing: initial;
}

.qanwser .client-comment {
    border-bottom: 1px solid #00000017;
    margin-bottom: 1%;
    padding-bottom: 1%;
    padding: 3% 3% 1% 3%;
}

.appointment-crf .card .col-lg-7 .stories {
    margin-top: 6%;
}

.appointment-crf .card .col-lg-7 h3 {
    font-size: 20px !important;
    margin: 0 !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
    color: #00234b !important;
    border-bottom: 1px solid #efefef00 !important;
    padding-bottom: 4px !important;
}

.appointment-crf .time_button-Wrapper {
    margin-top: 5%;
}

.appointment-crf .list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0px;
}

.can-ap-sec .bold {
    font-size: 16px;
    letter-spacing: normal;
    font-family: unset;
    text-transform: capitalize;
    margin-bottom: 8px;
    color: #000000;
}

.appointment-thanks {
    border: 1px solid #efecec;
}

.status-output #page-container {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

.status-output #sidebar {
    position: relative;
    background-color: transparent;
    background-image: transparent;
    display: none;
}

.row.can-ap-sec {
    margin: 10px 0;
    background-color: #f3f6f8;
    padding: 12px;
    border-radius: 8px;

    label {
        width: 45%;
        font-size: 11px;
    }

    span {
        font-size: 11px;
    }
}


.can-ap-sec label span {
    margin-right: 10px;
    color: #145e9c;
}

.time_button-Wrapper button span {
    border: none !important;
    background: #ebf6ff !important;
}

.select-vist-form span {
    font-size: 15px;
    text-align: right;
    margin-top: 5px;
}

.change-date-btn {
    position: absolute;
    top: 0px;
    z-index: 11;
    left: auto;
    right: 0px;
}

label.extra-style-head {
    position: relative;
    top: -23px;
    left: 37%;
    background-color: #fff;
    color: #1c4c76;
    font-size: 15px;
    border-radius: 6px;
    padding: 7px 10px;
    margin-bottom: -5px;
}

.back-home {
    position: absolute;
    top: -57px;
    left: 6px;
}

.back-home button.btn-previous {
    margin-left: -3px;
    background-color: #ffffff;
    border: 2px solid #ffffff !important;
    font-size: 18px;
}

.book-ap-right .header {
    top: -28px;
    z-index: 1;
    width: 100%;
    position: sticky;
}

.head-part-alert {
    width: 100%;
}

body .ui-dialog .ui-dialog-titlebar {
    padding: 9px 12px;
}

.reshdule-confirm {}

body .ui-dialog .ui-dialog-titlebar {
    background-color: #145e9c !important;
}

body .intech-term-condition-popup .ui-dialog .ui-dialog-titlebar {
    background-color: #002677 !important;

}

body .no-header .ui-dialog .ui-dialog-titlebar {
    background-color: #fff !important;
}

body .no-header .ui-dialog .ui-dialog-titlebar span {
    display: none;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
    color: #fff !important;
}

.custom-popup .ui-dialog-titlebar-icon {
    color: #000 !important;
}

body .custom-popup .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
    color: #fff !important;
    display: block !important;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
    color: #fff !important;
}

ul.add-extra-op {
    list-style: none;
    padding-inline-start: 0;
    margin: -14px 0 12px;
}

ul.add-extra-op li {
    display: flex;
}

ul.add-extra-op li span {
    color: #145e9c;
    margin-right: 10px;
}

ul.add-extra-op li h6 {
    margin-top: 4px;
    margin-left: 10px;
}

.abc-extra {
    float: right;
    margin-top: -33px;
}

.web-hid {
    display: none;
}


body .ui-inputtext {
    background: transparent;
}

body .ins-info .ui-dropdown {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    transition: border-color 0.2s, box-shadow 0.2s;
    line-height: 27px;
    /* padding: 6px 10px 6px 14px !important; */
    height: 40px;
}

body .ins-info .pi {
    font-size: 13px;
}

body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
    width: 100%;
    padding-right: 2em;
    height: 32px;
}

body .ui-state-disabled,
body .ui-widget:disabled {
    opacity: 0.5;
    color: #333333;
    // background-color: #e9ecef;
}

body .ui-datepicker table td.ui-datepicker-today>a.ui-state-active,
body .ui-datepicker table td.ui-datepicker-today>span.ui-state-active {
    color: #333333;
    background-color: #d0d0d0;
}

body .ui-datepicker table td>a.ui-state-active,
body .ui-datepicker table td>span.ui-state-active {
    color: #333333;
    background-color: #d0d0d0;
}

.custom-file-upload {
    background-color: #2462a5;
    border: 1px solid #ccc;
    border-color: #dcdcdc;
    display: inline-block;
    padding: 12px 12px;
    cursor: pointer;
    width: 100%;
    border-radius: .25rem;
    box-shadow: 0 1px 3px 0 #dee2e6;
    display: block;
    border-radius: 20px;
    color: #ffffff;
}

.terms-accept a {
    color: #007ad9;
    font-weight: 500;
}

body .demo .ui-autocomplete .ui-autocomplete-input {
    padding: 0.429em;
    padding-left: 28px;
}

.dep-title h3 {
    font-size: 25px;
    margin: 0;
    font-weight: 600;
    margin-bottom: 8px;
    color: #00234b;
    padding-bottom: 13px;
}

.book-ap-left ul.add-extra-op {
    margin: -4px 0 0px;
}

.content.new-style {
    border: 1px solid #ebebeb;
    padding: 20px;
    margin-top: 3%;
    border-radius: 5px;
}

.content.new-style h4 {
    margin-top: 0;
    position: absolute;
    background-color: #145e9c;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    left: 50%;
    top: -36px;
    font-size: 16px;
    transform: translate(-33%, -11%);
    -ms-transform: translate(-33%, -11%);
}

.contant-small .card .card-body {
    text-align: center;
}

.btn-cancel {
    background-color: #f3efef;
    color: #0e0e0e;
}

.content.new-style label {
    text-align: left;
}

#select-dob .form-control {
    max-width: 309px;
}

.content.new-style .card {
    margin-bottom: 0;
    box-shadow: none;
}

.content.new-style ul.add-extra-op {
    margin: 16px 0 0px;
}

.first-upload {
    padding: 30px 10px;
    background-color: aliceblue;
    margin: 10px 0;
}

.content.new-style {
    position: relative;
}

.content.new-style .back-home {
    position: absolute;
    top: -11px;
    left: 6px;

}

.custom-dilog .ui-dialog-resizable .ui-dialog-content {
    overflow: auto;
    width: 700px;
}

body.blur::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}

.confirm-popup .ui-dialog-visible {
    align-items: inherit;
}

body .confirm-popup .ui-dialog-content {
    padding: 3em 2em;
    min-height: 200px;
    border: none;
}

body .confirm-popup .ui-dialog {
    padding: 0;
    box-shadow: none;
    margin-top: 40px;
}

body .confirm-popup .ui-confirmdialog-message ul {
    list-style-type: none;
    padding: 0;
}

body .confirm-popup .ui-confirmdialog-message ul strong {
    width: 124px;
    display: inline-block;
}

.ui-confirmdialog-message ul {
    list-style-type: none;
}

.ui-confirmdialog-message ul li {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
}

body .confirm-popup .ui-dialog-footer {
    border: none;
    text-align: center;
    padding-bottom: 24px;
}

body .confirm-popup .ui-dialog-title {
    color: #fff !important;
    font-size: 16px;
}

body .confirm-popup button {
    padding: 7px 8px 8px 12px;
    background-color: #c41a20;
    border: 2px solid #c41a20;
    /* line-height: 43px; */
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    z-index: 99;
    position: relative;
    line-height: 26px;
    min-width: 163px;
    margin-right: 15px !important;
}

body .confirm-popup button+button {
    background-color: #45BD92;
    color: #fff;
    border-color: #45BD92;
}

body .confirm-popup .ui-button .ui-button-text {
    display: inline-block;
    padding: 0 5px !important;
}

body .confirm-popup .pi {
    position: static;
}

body .checkinform .ui-button:enabled:hover {
    background-color: transparent;
    color: #ffffff;
    border-color: #dcdcdc;
}

body .checkinform .ui-inputtext:enabled:focus:not(.ui-state-error) {
    border-color: #dcdcdc;
}

body .checkinform .ui-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: #dcdcdc;
}

.ui-button-icon-only .ui-button-icon-left::before {
    display: none;
}

body .search-container .ui-autocomplete .ui-autocomplete-input {
    height: 48px;
    background: #fff;
    border: 0;
    padding: 5px 9px !important;
}

.search-container .ui-autocomplete-dropdown {
    position: relative;
    right: 0;
    top: 6px;
    background: transparent;
    border: none;
}

.search-container .ui-autocomplete-dropdown:hover {
    background-color: transparent !important
}


.search-container .ui-autocomplete-dropdown .pi-caret-down::after {
    content: "\f107";
    font: 14px/1 FontAwesome;
    font-weight: 900;
    color: #000;
}

body .search-container input {
    color: #858585;
    font-size: 13px;
}

.search-container .ui-autocomplete {
    background: #fff;
    margin-right: 5px;
    display: flex;
    border-radius: 4px;
}

body .timezone .ui-dropdown {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    transition: border-color 0.2s, box-shadow 0.2s;
    width: 100%;
    display: block;
}

body .ui-widget {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 400;
}

body .ui-widget::placeholder {
    color: #858585;
    font-size: 13px;
}

.noSymptom.custom-cal button {
    opacity: 0.5 !important;
}

.custom-cal button {
    background: transparent;
    border: none;
    background-image: url('assets/img/cal-icon.png');
    background-size: cover;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: 44px;
    background-color: transparent;
    width: 44px !important;
    position: absolute;
    opacity: 1 !important;
    right: 7px;
}

.custom-cal button:focus,
.custom-cal button:hover {
    background-color: transparent !important;
    border: none;
}

.locationcss .input-group {
    border-color: #dcdcdc00;
    color: #858585;
    font-size: 13px;
    height: 51px !important;
    padding: 6px 5px;
    background: #fff;
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
    border-radius: 10px;
}

.searchdiv .locationcss .input-group {
    background: #f2f8f6;

    flex-wrap: initial;
}

body .input-miles .ui-dropdown {
    background: #f2f8f6;
    border-radius: 10px;
    color: #3F3E3E !important;
    font-size: 13px;
    font-weight: 500;
    border: none;
    height: 50px;
    line-height: 26px;
}

body .input-miles .ui-dropdown:before {
    content: "\f018";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 18px;
    color: #495057;
    left: 14px;
}

body .input-miles .ui-dropdown:focus {
    outline: none;
}

body .input-miles .ui-dropdown .pi {
    font-size: 12px;
    color: #495057;
}

body .input-miles .ui-inputtext {
    padding: 12px 10px 6px 34px !important;
    color: #000;
    font-weight: 500;
}

body .input-miles .ui-dropdown .ui-dropdown-trigger {
    background: #f2f8f6;
    border-radius: 10px;
    right: 4px;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    box-shadow: none;
    border: none;
}
body .inteck-insurance  .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    border: 1px solid #8e9aa6;
}

.searchdiv .input-group-text {
    background-color: #f2f8f6 !important;
}

.searchdiv .form-control {
    background-color: transparent !important;
}

body .ui-paginator {
    background-color: transparent;
    border: none;
    padding: 0;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-color: #145e9c;
    color: #ffffff;
    border-radius: 4px;
}

.locationcss input {
    border: none;
    padding: 0 12px !important;
    width: 339px;
    color: #000;
    font-weight: 500;
    color: #3F3E3E !important;
    font-size: 13px !important;
}

.locationcss input::placeholder {
    color: #3F3E3E !important;
    font-size: 13px !important;
}

.fxt-transformY-50 {
    margin: 0 !important;
}

body .note-section .ui-rating a .ui-rating-icon {
    font-size: 30px;
}

body .add-note .ui-rating a .ui-rating-icon {
    font-size: 30px;
}


.card-header {
    padding: 8px;
}

.card-header h4 {
    font-size: 1rem;
}

body .note-section .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

body .add-note .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

body .add .ui-dialog .ui-dialog-content {

    border: none !important;

}

body .delete-insurance .ui-dialog .ui-dialog-footer button {
    background: #eae7e7 !important;
    z-index: 99;
    position: relative;
    line-height: 26px;
    min-width: 163px;
    margin-right: 15px !important;
    border-radius: 4px;
    color: #333 !important;
    border: none !important;
    padding: 3px 12px;
    outline: none !important;
}

body .delete-insurance .ui-dialog .ui-dialog-footer button+button {
    background-color: #c52127 !important;
    color: #fff !important;
}

body .delete-insurance .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
    color: #fff !important;
    display: block !important;
}

body .delete-insurance ul {
    margin-bottom: 0;
}

body .delete-insurance textarea.form-control {
    height: auto;
    resize: none;
}

.ui-carousel-next {
    display: none;
}

.locationcss .ui-autocomplete-loader {
    top: 20px !important;
    right: 0px;
    display: none;
}

.locationcss .pi-spinner {
    border: 2px solid #9798a4;
}

.businessownerinsurance .ui-multiselect .ui-multiselect-label {
    padding: 0.55rem 0.75rem;

}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
    color: #333 !important;
    background-color: #fff !important;
}

.businessownerinsurance .ui-multiselect {
    border-color: #dcdcdc;


}

.businessownerinsurance .ui-multiselect-trigger-icon {
    font-size: 12px;
}

.businessownerinsurance .ui-multiselect-panel {
    padding: 0.2em;
    position: absolute;
    min-width: 12em;
    left: 0 !important;
    right: 0 !important;
}

body .schedule-checking-screen .ui-dropdown-items-wrapper {
    max-height: 170px !important;
}
body .schedule-checking-screen .ui-dropdown-label-container  {
       overflow: hidden;
}

body .schedule-checking-screen .ui-dropdown {
    line-height: 27px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
    height: 42px;

    .ui-dropdown-label {
        height: 38px;
        line-height: 29px;
        font-size: 10px;
    }
}
body .schedule-checking-screen .insutance-card .ui-dropdown {
    line-height: 27px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 50px !important;
    border-radius: 10px;
    border: 1px solid  #9999bb;

    .ui-dropdown-label {
        height: 38px;
        line-height: 36px;
        font-size: 10px;
    }
    .ui-dropdown-trigger{
        background-color: transparent;
    }
}
.form-section .pi-spin {
    font-size: 12px;
}
body .schedule-checking-screen .ui-dropdown .ui-dropdown-trigger {
    border-radius: 0 4px 4px 0;
}

body .schedule-checking-screen .ui-dropdown .ui-dropdown-label {
    color: #1C1C1C;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 10px 6px 10px !important;
    line-height: 35px;
}
body .schedule-checking-screen .insutance-popup .ui-dropdown{
    height: 50px !important;
    font-size: 13px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: inherit;
    border: 1px solid #9999bb;
}
body .schedule-checking-screen .insutance-popup .ui-dropdown .ui-dropdown-trigger{
    background: transparent;
}
@media only screen and (max-width: 920px) {
    body .schedule-checking-screen .input-mask .ui-inputtext {
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        color: #1C1C1C;
    }

    body .schedule-checking-screen ss.ui-inputtext:enabled:focus:not(.ui-state-error) {
        color: #1C1C1C;
    }

    body .schedule-checking-screen p-inputmask.ng-dirty.ng-invalid>.ui-inputtext {
        border: none;
    }

    body .schedule-checking-screen .input-mask .ui-inputtext::placeholder {
        color: #1C1C1C;
        font-size: 1px;
    }

    body .schedule-checking-screen .ui-inputtext:enabled:focus:not(.ui-state-error) {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1) !important;
        border-color: #dcdcdc !important;
    }
}

@media only screen and (max-width: 767px) {
    body .add-pharmacy .ui-autocomplete .ui-autocomplete-input{
        background-color: #fff;
        border-radius: 4px;
        height: 50px !important;
        color: #000;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        padding-left: 10px !important;
        border-radius: 10px;
        border-color: #9999bb;
    }
    .appointment-crf h4.book-date-head {
        top: -26%;
       
    }
    body .schedule-checking-screen .input-mask .ui-inputtext {
        background: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        color: #1C1C1C;
    }

    body .schedule-checking-screen ss.ui-inputtext:enabled:focus:not(.ui-state-error) {
        color: #1C1C1C;
    }

    body .schedule-checking-screen p-inputmask.ng-dirty.ng-invalid>.ui-inputtext {
        border: none;
    }

    body .schedule-checking-screen .input-mask .ui-inputtext::placeholder {
        color: #1C1C1C;
        font-size: 1px;
    }

    body .schedule-checking-screen .ui-inputtext:enabled:focus:not(.ui-state-error) {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1) !important;
        border-color: #dcdcdc !important;
    }

    body .ui-dropdown-label {
        padding: 8px 10px !important;
        height: 33px;
        font-size: 11px;
    }

    body .delete-insurance .ui-dialog ul li strong {
        display: block;
    }

    body .delete-insurance .ui-dialog .ui-dialog-footer button {
        min-width: 86px;
        width: auto;
    }

    .locationcss input {
        border: none;
        padding: 0 12px !important;

    }

    .custom-cal button {
        background-size: 36px;
        right: 5px;
    }

    body .confirm-popup button {
        min-width: 128px;
        margin-right: 15px !important;
    }

    .image-preview-container {
        width: 119px;
        padding: 1px;
        margin-bottom: 20px;
    }

    body .demo .ui-autocomplete .ui-autocomplete-input {
        padding: 0.429em;
        padding-left: 10px;
    }

    iframe.mb-4 {
        display: none;
    }

    .ext-head {
        display: block;
        font-size: 11px;
        margin-top: -5px;
        color: #9a9898;
    }

    .mob-hid {
        display: none
    }

    ul.add-extra-op {
        margin: -4px 0 8px;
        text-align: center;
    }

    ul.add-extra-op li {
        display: inline-block;
        float: none;
    }

    ul.add-extra-op li h6 {
        margin-top: 4px;
        margin-left: 0;
        font-size: 10px;
    }

    .content.new-style h4 {
        margin-top: 0;
        position: absolute;
        background-color: #145e9c;
        color: #ffffff;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #e5e5e5;
        left: 50%;
        top: -61px;
        font-size: 11px;
        transform: translate(-50%, 67%);
        white-space: nowrap;
    }

    .container-fluid {
        padding-left: 3px;
        padding-right: 3px;
    }

    body .ui-dialog {
        width: 85vw !important;
    }
    body .credit-card-popup .ui-dialog{
        width: 95vw !important;
    }
    body .credit-card-popup  .ui-dialog .ui-dialog-content{
        border: none;
    }
    body .credit-card-popup  .ui-dialog .ui-dialog-content #this-number{
        position: relative;
        top: 13px;
        width: 33px;
        height: 18px;
        left: -14px;
    }
  
    body .credit-card-popup .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
        color: #333 !important;
        position: relative;
        top: 5px;
    
    }
    body .credit-card-popup .ui-dialog .ui-dialog-titlebar .ui-dialog-title{
        font-size: 20px;
        color: rgb(40, 55, 121) !important;
        margin: 0.5em 0px;
    }
 
    .pat-name {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 18px;
    }

    .can-ap-sec label {
        width: auto;
    }

    label.extra-style-head {
        left: 26%;
    }

    .header-logo {
        margin-top: 5px;

    }

    // .content {
    //     margin: 10px auto;
    // }

    p.mt15.dep-title {
        padding: 0px 0px 5px;
    }

    .dep-title h3 {
        font-size: 18px !important;
        padding-bottom: 5px !important;
    }

    .select-vist-form span {
        font-size: 15px;
        margin-bottom: 5px;
        text-align: left !important;
    }

    .ap-slot-right {
        margin-top: 10%;
    }

    p.description {
        display: none;
    }

    .gogle-map {
        display: none;
    }

    .form-group.select-vist-form {
        margin-top: 0px;
    }

    

    .select-vist-form {
        text-align: center !important;
        padding-bottom: 5px;
    }

    // .booking-form {
    //     padding: 3px 10px 14px !important;
    // }

    .time_button-Wrapper button {
        width: 46% !important;
        margin-right: 8px !important;
    }

    .time_button-Wrapper button li a.confirm-btn {
        font-size: 13px !important;
        padding: 13px 4px !important;
    }

    .time_button-Wrapper .loadMore {
        width: 45% !important;
    }

    .time_button-Wrapper .loadMore {
        padding: 10px 20px !important;
    }

    li.dep-det-op {
        display: flex;
        font-size: 13px;
    }

    .signature-box {
        margin-bottom: 0px;
        margin-top: 22px;
    }

    .content.new-style .signature-box {
        margin-top: 10px;
    }

    label.signature-lable {
        margin-top: 3px;
    }

    .dep-title .department_detail span {
        display: contents !important;
        padding-right: 8px;
    }

    .appointment-thanks-2 {
        font-size: 17px !important;
    }

    .appointment-thanks-2 #btnskip {
        margin-top: 10px;
        padding: 5px 17px !important;
    }

    .time_button-Wrapper button li a.confirm-time {
        padding: 11px 0px !important;
        font-size: 13px;
    }

    .book-ap-right {
        margin-top: 5%;
        padding: 20px 10px;
    }

    .book-ap-left p.mt15.dep-title {
        padding: 10px 13px 0px;
        margin-bottom: 12px;
    }

    h4.heading-appointment {
        margin-top: -14px;
    }


    h4.card-title.pat-info {
        left: 52%;
        top: -59px;
        font-size: 13px
    }

    .editbtn button {
        margin-left: 10px;
    }

    .book-con .col-xs-6 {
        width: 50%;
    }

    .appointment-thanks-2 .custom-upload label {
        font-size: 16px !important;
    }

    .content.contant-small {
        border: 1px solid #ebebeb;
        padding: 20px;
        margin-top: 3%;
        border-radius: 5px;
    }

    .contant-small .card .card-header .card-title {
        color: #fff;
        font-size: 18px;
    }

    .submit-section .submit-btn {
        border-radius: 0;
    }

    #msform .card-header {
        background-color: #145e9c !important;
    }

    .ap-slot-right {
        padding: 15px 15px;
    }

    .ap-slot-right h4.up-head {
        left: 18%;
    }

    .row.can-ap-sec {
        padding: 10px 0;
    }

    label.p-10.new-head {
        font-size: 12px;
    }

    .department_detail svg {
        width: 26px !important;
        margin-top: 7px;
    }

    .appointment-thanks-2 p {
        font-size: 14px;
        line-height: 1.5;
    }

    .row.book-con label {
        font-size: 13px;
    }

    .row.book-con h6 {
        line-height: 1.6em;
    }

    .mob-sign-op {
        position: absolute;
        right: 15px;
        bottom: -12px;
    }

    .terms-con .ui-dialog .ui-dialog-content {
        height: 300px;
    }

    .appointment-thanks-2 .book-con {
        padding-bottom: 0px;
    }

    #msform {
        margin-top: 6%;
    }

    .center-small {
        text-align: center;
    }

}

@media only screen and (max-width: 480px) {
    .ui-confirmdialog-message ul li {
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 500;
    }

    .appartment-types label {
        white-space: nowrap;
    }

    body .confirm-popup .ui-confirmdialog-message ul strong {
        width: 100%;
        display: block;
    }

    select.form-control {
        background-size: 13px;
    }

    label {
        display: inline-block;
        margin-bottom: .5rem;
        font-size: 10px;
    }

    .verify-btn .btn {
        padding: 12px 24px;
        min-width: auto;
    }

    .Insurance-type1.insurance2 label {
        font-size: 10px;
    }

    body .ui-inputtext {
        padding: 6px 10px !important;
        height: 36px;
        font-size: 11px;
    }
    body .form-section-inner .ui-inputtext {
        height: 32px;
    }
    body .book-date-head .ui-inputtext {
        height: 40px;
    }

    h4.book-date-head span {
        bottom: 5px;
    }

    .form-control {
        height: 36px !important;
        font-size: 11px;
    }

    span.input-group-addon.book-form {
        display: none;
    }

    .form-control.icons {
        padding: 6px 10px;
    }

    .custom-dilog .ui-dialog-resizable .ui-dialog-content {
        overflow: auto;
        width: 100%;
    }

    .custom-dilog .ui-dialog-resizable .ui-dialog-content .button-round {
        min-width: 151px;
    }

    ul.add-extra-op li {
        display: block;
        text-align: center;
    }

    ul.add-extra-op li {
        display: inline-block;
        text-align: left;
        float: none;
        margin: 0;
        margin-right: 25px;
    }

    h4.card-title.pat-info {
        font-size: 12px;
        top: -57px;
    }

    h4.heading-appointment {
        font-size: 15px;
        line-height: 1.6em;
    }

    .multistepform h4.card-title.pat-info {
        left: 50%;
        top: -43px;
        font-size: 13px;
        float: none;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -38%);
    }

    body .verify-detail .ui-inputtext {
        font-size: 13px !important;
    }
    body .verify-detail .ui-inputtext::placeholder{
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 420px) {
    .ui-confirmdialog.ui-dialog .ui-dialog-content {
        padding: 5px 1em;
    }

    body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
        color: #fff !important;
        font-size: 13px;
    }
   .form-section .pi-spin {
              font-size: 12px;
    }
    body .viewframe .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
        color: #fff !important;
        font-size: 13px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }
    body .confirm-popup button .pi {
        position: relative;
        top: 2px;
        right: 5px;
        margin-right: 7px;
    }

    body .ui-dialog .ui-dialog-footer button {
        margin: 0 0.5em 0 0;
        margin-bottom: 10px;
        max-width: 200px;
    }

    body .verify-insurance .ui-dialog .ui-dialog-footer button {
        width: auto;
    }

    body .endConfirmation .ui-dialog .ui-dialog-footer button {
        margin: 0 0.5em 0 0;
        width: auto;
        margin-bottom: 10px;
        max-width: 200px;
    }

    body .confiramation .ui-dialog .ui-dialog-footer button {
        margin: 0 0.5em 0 0;
        width: auto;
        margin-bottom: 10px;
        max-width: 200px;
        font-size: 12px;
    }

    body .confiramation .ui-dialog .ui-dialog-footer {
        text-align: center;
    }

    .content.new-style ul.add-extra-op {
        margin: 16px 18px 0px;

    }

    ul.add-extra-op li h6 {
        margin-left: 25px;
        margin-top: 0px;

    }
}

@media only screen and (max-width: 320px) {

    .alert {
        padding: .75rem 1.0rem;
    }

    span.input-group-addon.book-form {
        left: 22px;
    }

    .book-ap-right {
        margin-top: 5%;
    }

    .appointment-thanks-2 p {
        font-size: 14px !important;
        line-height: 1.5;
    }

    .row.book-con label {
        font-size: 13px;
    }

    .row.book-con h6 {
        line-height: 1.6em;
    }



}

.bookingCalander .ui-datepicker table td.ui-datepicker-today>a,
body .ui-datepicker table td.ui-datepicker-today>span {
    background-color: #fff;
    color: #333333;
}

body .verify-insurance .ui-button {
    background-color: #45BD92;
    border: 1px solid #45BD92;
}

.custom-cal .ui-calendar {
    display: block;
    border: 1px solid #dcdcdc;
}

.custom-cal.custom-cal-type2 .ui-calendar {
    border: none;
}

.custom-cal input {
    border: none;
}

.custom-cal.custom-cal-type2 input {
    color: transparent;
    background-size: 27px;
    background-color: transparent;
    width: 24px;
}

.invalidField-message {
    color: #F1807F !important;
    font-size: 12px !important;
    margin: 0;
}

.red-font {
    color: #ef7e7e;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    color: #333333;
    margin-bottom: 3%;
}

.ui-tabview .ui-tabview-title {
    vertical-align: middle;
    color: white;
}

.card.assigin-card {
    border: 1px solid #0000001c;
    padding: 10px 5px;
    /* -webkit-box-shadow: 0 0 13px 0 rgb(236 236 241 / 44%); */
    box-shadow: 0 0 13px 0 rgb(236 236 241 / 0%);
}

.noSymptom input {
    background: #dddddd;
    opacity: 0.5;
}

.on-baording-header {
    line-height: 23px;
    font-weight: 500;
    top: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    position: fixed;
    z-index: 99999;
    padding: 17px;
}

.on-baording-header #logo {
    top: 5px;
}

.otp-screen {
    text-align: center;
}

.verify-otp input {
    display: inline-block;
    width: 20%;
    margin-right: 5px;

}

.tabviewdiv .ui-tabview {
    display: flex;
}

.ui-tabview-panels {
    width: 100%;
    border: none !important;
}

.tabviewdiv .ui-tabview .ui-tabview-nav {
    display: grid;
    border-right: 2px solid #0058b3 !important;
    padding: 0 0 !important;
}

.tabviewdiv .ui-tabview .ui-tabview-title {
    vertical-align: middle;
    color: #736f6fb5;
    font-size: 17px;
}

.tabviewdiv .ui-state-active .ui-tabview-title {
    vertical-align: middle;
    color: #000;
}

.tabviewdiv .ui-state-active {
    background-color: #ffffff !important;
    border: none !important;
}

.tabviewdiv .ui-state-default {
    background-color: #ffffff !important;
    border: none !important;
}

.table-bx-area .ui-table thead.ui-table-thead tr th:last-child {
    border-top-right-radius: 20px;
}

.table-bx-area .ui-table thead.ui-table-thead tr th:first-child {
    border-top-left-radius: 20px;
}

.table-bx-area .ui-table tbody.ui-table-tbody {
    border: 1px solid #ccc;
    border-top: 0;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

}

.table-bx-area .ui-table .ui-table-tbody>tr>td {
    padding: 0.571em 0.857em;
    border-bottom: 1px solid #ccc !important;
}
.page-content {
    padding: 106px 0px 27px 116px;
}

.form-control.invalid-Input {
    border-bottom: 2px solid red !important;
}
body .form-section .ui-inputtext[readonly]{
    background-color:#e9ecef ; 
}
body .immegration-popup .ui-dialog .ui-dialog-titlebar-icons{
    display: none;
}
body .immegration-popup .ui-dialog .ui-dialog-footer button {
    margin: 0 0.5em 0 0;
    width: auto;
    padding: 8px 12px;
}
body .form-section-inner .ui-chkbox .ui-chkbox-box .ui-chkbox-icon{
line-height: 19px;
font-size: 15px;
}

body .card-content .ui-inputtext{
background: #fff;
height: 33px;
padding-left: 12px !important;
}
body .register-box .ui-inputtext{
    background: #fff;
    height: 39px;
    padding-left: 12px !important;
    }
.portal-booking-form .ui-datepicker.ui-datepicker-inline{
    border: none;
}
body .portal-booking-form .ui-datepicker table th {
    padding: .5rem;
    color: #070786;
    text-align: center;
    font-size: 13px;

}
body .portal-booking-form .ui-datepicker table  tbody{
    border-top: 1px solid #ccc;
}
body .portal-booking-form.portal-booking-form  .ui-calendar{
   
}
body .portal-booking-form.portal-booking-form  .ui-calendar input{
    display:none;
}
body .portal-booking-form .ui-datepicker table td{
    padding: 0.5em;
    width: auto;
    height: auto;
    font-size: 19px;
}
body  .portal-booking-form .ui-datepicker table td > a{
    line-height: 40px;
    border-radius: 100%;
    padding: 0;
    width: 40px;
    height: 40px;
}
body .portal-booking-form .ui-datepicker table td:hover a{
    background: transparent;   
}
body .portal-booking-form .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
    background: transparent;
    color: #333;
}

body .portal-booking-form  .ui-datepicker table td > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}
body .my-profile-pages .ui-inputtext{
    height: 32px;
    padding: 6px 10px !important;
}


body .my-profile-pages .ui-dropdown {
    border: 1px solid #dcdcdc;
}
body .my-profile-pages .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    border: 1px solid #dcdcdc;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
    border: 1px solid #dcdcdc;
}
body .my-profile-pages .ui-dropdown-trigger-icon{
    font-size: 12px;
}
.paypopup{
    .ui-widget-header{
        display: none;
    }
    .ui-dialog-content{
        border-radius: 10px;
    }

    h4{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }

    p{
        margin-bottom: 26px !important;
        margin-top: 14px !important;
        text-align: center;
        
    }
    .no{
        background: #f7f7ff;
        padding: 5px;
        position: relative;
        border: none;
        border-radius: 4px;
        color: #333;
        font-size: 12px;
        min-width: 98px;
        margin-right: 10px;
    
    }
    .yes{
        background: #4378be;
        padding: 5px;
        position: relative;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        min-width: 98px;
    
    }
}

body .Reason-card .ui-listbox .ui-chkbox{
    display: none;
}
body .Reason-card .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight{
    background: transparent;
    color: #000;
}
body .Reason-card .ui-listbox .ui-listbox-list .ui-listbox-item:focus{
    outline: none;
    box-shadow: none;
}
body .Reason-card .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    overflow: hidden;
    position: relative;
    font-size: 12px;
    line-height: 17px;
}
body .Reason-card .Reason-cardui-listbox-filter-container{
    width: 100%;
}
@media(max-width:768px) {
    body .portal-booking-form .ui-datepicker table td > a {
        line-height: normal;
        border-radius: 100%;
        padding: 0;
        width: auto;
        height: auto;
    }
}
.password-change .ui-dialog-content{
 overflow: visible;
}
body .password-change .ui-dialog .ui-dialog-titlebar {
    background-color: #145e9c !important;
    background-color: #fff !important;
}
body .password-change  .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span{
    color: #333 !important;
    font-size: 12px;
}
body .password-change .ui-dialog .ui-dialog-titlebar .ui-dialog-title{
        color: #333 !important;
}
body .password-change .ui-dialog .ui-widget-header {
display: none;
}
body .password-change .ui-dialog .ui-dialog-content {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    border-radius: 10px;
    padding: 23px;
}
#ngx_captcha_id_4367{
    text-align: center;
}
body .credit-card-popup {
    z-index: 99999999999;
    position: relative;
}
body .edit-screen-popup .ui-dialog .ui-dialog-titlebar .ui-dialog-title{
    color: #000 !important;
}
body .edit-screen-popup  .ui-dialog .ui-dialog-titlebar-icon span{
    color: #000 !important;
}
body .form-floating .ui-autocomplete .ui-autocomplete-input {
    padding: 0.429em;
    height: 50px !important;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
    border-radius: 10px;
    border-color: #9999bb;
    color: #333;
    padding-left: 12px !important;
}
body .form-floating .ui-autocomplete .ui-autocomplete-input:focus{
    border-color: #9999bb;
}
body .form-floating .ui-autocomplete .ui-autocomplete-input::placeholder {
    /* Your desired placeholder styles */
    color: #aaa; /* Example: light gray color */
    font-size: 14px; /* Example: adjust font size */
    opacity: 1; /* Ensure opacity is fully visible */
    font-weight: 400;
}
body .Pharmacy-zip .form-floating .ui-autocomplete .ui-autocomplete-input{
    font-size: 13px;
    font-weight: 400;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
body .reasonfor-vist-screen .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
    outline: none;
    box-shadow: none;
    border-color: #dcdcdc;
  }

.reasonfor-vist-screen  .ui-inputtext {
    border-color: #dcdcdc;
    color: #858585;
    font-size: 13px;
    padding: 8px 8px !important;
    width: 100%;
    height: 50px !important;
    border: none;
    background: #fff;
    border: 1px solid #9999bb;
    padding: 5px 12px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    bottom: 4px;
}

.reasonfor-vist-screen .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
    font-size: 15px;
    padding: 0.2145em 0.429em;
    margin: 5px 0.286em 0 0;
    background: #f1f5f9;
    color: #ffffff;
    border-radius: 6px;
    text-transform: capitalize;
    margin-top: 0;
}  


.reasonfor-vist-screen  .ui-inputtext .ui-autocomplete-input-token  input{
    font-size: 15px !important;
    width: auto;

}
.reasonfor-vist-screen  .ui-inputtext .ui-autocomplete-input-token  input::placeholder{
    font-size: 13px;
    font-weight: 400;
}

.reasonfor-vist-screen .ui-autocomplete-token-icon{
    margin-top: -8px;
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    font-size: 9px !important;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 2px;
    width: 15px;
    height: 15px;
    color: #1e293b;
    line-height: 11px;
}
.ui-autocomplete-token-label{
    color: #333;
}
body   .reasonfor-vist-screen .ui-listbox .ui-listbox-header .ui-listbox-filter-container{
    width: 100%;
}

body   .reasonfor-vist-screen .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-inputtext {
    padding:8px 5px !important;
    border: 1px solid #ccc;
}


body .reasonfor-vist-screen .ui-listbox .ui-listbox-list li{
    margin: 0;
    padding: 9px 10px;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
}
body .reasonfor-vist-screen .ui-listbox .ui-listbox-list::-webkit-scrollbar {
    width: 12px;
}
                    
body .reasonfor-vist-screen .ui-listbox .ui-listbox-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
                    
body .reasonfor-vist-screen .ui-listbox .ui-listbox-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
body .delete-popup  .ui-dialog-resizable .ui-dialog-content {
    overflow: visible;
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
}
body .delete-popup    .ui-dialog .ui-dialog-footer {
    border: none;
    padding-bottom: 20px;
    text-align: center;
}
body .delete-popup .ui-dialog .ui-dialog-footer button {
    background: #eae7e7 !important;
    z-index: 99;
    position: relative;
    line-height: 26px;
    min-width: 135px;
    margin-right: 15px !important;
    border-radius: 4px;
    color: #000 !important;
    border: none !important;
    padding: 3px 12px;
    outline: none !important;
}
body .delete-popup .ui-dialog .ui-dialog-footer button + button {
    background-color: #c52127 !important;
    color: #fff !important;
}

.reasonForvist .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
    margin: 0;
    padding: 1em 0.8571em;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    border-radius: 0;
}
